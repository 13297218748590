<template>
  <div class="footpic">
    <img :src="MediaURL + pic.filename" />
    <button @click="$emit('deletepic')" @touchstart="$emit('deletepic')"></button>
    <div class="infofootpic">
      <figure :class="['statuscirlcle', statusclass[pic.status]]"></figure>
      <p class="date">{{ ago(pic.uploaddate) }}</p>
      <p class="location">Location: {{ pic.location }}</p>
      <p class="status">Status: {{ status[pic.status] }}</p>
    </div>
  </div>
</template>
<script>
import { MediaURL } from "../api";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default {
  name: "footpic",
  props: ["pic"],
  emits: ["deletepic"],
  setup() {
    const store = useStore();
    function locationname(qr) {
      //for a read able location name
      if (typeof store.state.qr_code_fullnames !== "undefined") {
        return store.state.qr_code_fullnames[qr];
      } else {
        return qr;
      }
    }
    function ago(uploaddate) {
      // used to generate a read able date string
      const date = Date.parse(uploaddate);
      const now = new Date();
      const diff = now - date;
      const diffDays = Math.floor(diff / 86400000); // days
      const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes

      let str = "";
      if (diffDays > 1) str = `vor ${diffDays} Tagen und ${diffHrs} Stunden`;
      else if (diffDays == 1)
        str = `vor einem ${diffDays} Tag und ${diffHrs} Stunden`;
      else if (diffHrs > 1)
        str = `vor ${diffHrs} Stunden und ${diffMins} Minuten`;
      else if (diffHrs == 1)
        str = `vor einer ${diffHrs} Stunde und ${diffMins} Minuten`;
      else str = `vor ${diffMins} Minuten`;

      return str;
    }
    return {
      MediaURL,
      locationname,
      ago,
      status: computed(() => store.state.status),
      statusclass: ["infochecking", "infochecked", "inforejected"],
    };
  },
};
</script>