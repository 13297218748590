<template>
  <main class="allimages">
    <p v-if="allimageslength == 0">Keine Bilder vorhanden!</p>
    <footpic
      @deletepic="deletpic(img.filename, index)"
      :pic="img"
      :key="img.id"
      v-for="(img, index) in allimagesarry"
    ></footpic>
    <figure v-if="loaded.length != allimageslength" class="spinner"></figure>
  </main>
</template>
<script>
import footpic from "../components/footpic.vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { postData, baseURL, MediaURL } from "../api";
import { useRouter } from "vue-router"

export default {
  name: "allimages",
  components: {
    footpic,
  },
  setup() {
    const store = useStore();
    const loaded = ref([])
    const router = useRouter()
    allimages();
    async function allimages() {
      let images = await postData(
        baseURL + "api/img/myimages",
        JSON.stringify({ data: null }),
        store.state.token
      );
      if (typeof images === "object" && images.status == "ok") {
        store.commit("setallimages", images.images);
        loadimages();
      } else {
        store.dispatch("firenotice", {
          notice: images.error,
          notice_type: "alert-danger",
        });
      }
    }
    function loadimages() {
      for (let el in store.state.allimages) {
        let download = new Image();
        download.src = MediaURL + store.state.allimages[el].filename;
        download.onload = function () {
          loaded.value.push(el);
        };
      }
    }
    async function deletpic(img, index) {
      let question = window.confirm(
        "Möchstest du das Bild unwiderruflich löschen ?"
      );
      if (question) {
        let res = await postData(
          baseURL + "api/img/delete",
          JSON.stringify({ filename: img }),
          store.state.token
        );
        if (typeof res === "object" && res.status == "ok") {
          store.commit("removeimage", index);
          loaded.value.splice(loaded.value.indexOf(index), 1);
          store.commit("settakenimages", res.takenimages);
        } else {
          store.dispatch("firenotice", {
            notice: res.message,
            notice_type: "alert-danger",
          });
        }
      }
    }
    const allimagesarry = computed(() => {
      let obj = [];
      for (let el in store.state.allimages) {
        if (loaded.value.includes(el)) obj.push(store.state.allimages[el]);
      }
      return obj;
    });
    return {
      allimagesarry,
      allimageslength: computed(() => store.state.allimages.length),
      MediaURL,
      deletpic,
      loaded,
    };
  },
};
</script>